import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';
import * as Leaflet from 'leaflet';
import 'leaflet-rotatedmarker';
import { ApiService } from 'src/app/services/api.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-seguir-objeto',
  templateUrl: './seguir-objeto.page.html',
  styleUrls: ['./seguir-objeto.page.scss'],
})
export class SeguirObjetoPage implements OnInit {

  map: Leaflet.Map;
  lat: number = 21.9934775;
  lng: number = -99.0176878;
  id:any;
  interval:any;
  markers:any = [];
  markersHistorial:any = [];
  polylineEdit:any;
  polyline:any;
  numTempObjeto:number = 0;
  lineaTempObjeto:any = [];
  lineasPushAgrupadas:any = [];
  lineasObjetos:any;
  dataObjeto:any = {};
  open_close:boolean = false;
  tipo_status:any;
  fecha:any;
  segmentModel = "info";
  fecha_server:any;
  height:string = "350px";
  url:any;

  constructor( private route: ActivatedRoute, public navCtrl: NavController, private _api: ApiService, private platform: Platform,
              private sanitizer: DomSanitizer ) { 
    this.id = this.route.snapshot.params.id;    
    if(!this.id){
      this.navCtrl.navigateRoot("home/objetos");
    }

    platform.ready().then(() => {
      console.log('Width: ' + platform.width());
      console.log('Height: ' + platform.height());
      this.height = `${platform.height()/3.5}px`;
      console.log( this.height );
    });
  }

  ngOnInit() {

    setTimeout(()=>{
      this.leafletMap();
      this.seguirUnidad();
    }, 500);

    this.interval = setInterval(()=>{
      this.seguirUnidad();
    }, 10000);

    
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }

  leafletMap(){

    let osmUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		let osmAttrib = '&copy; OSM - contributors';
		let osm  = Leaflet.tileLayer(osmUrl, { maxZoom: 21, attribution: osmAttrib });
    this.map = Leaflet.map('map-seguir', { attributionControl: false, center: [this.lat, this.lng], zoom: 16 });

    const here = {
      id: '6AJleReU2wy5FIYdcHUZ',
      code: '2sYGPV-IeanNImtVlcmNpw'
    }
    const style = 'normal.day';

    Leaflet.control.layers({
      'OSM': osm.addTo(this.map),
      "Humanitarian": Leaflet.tileLayer('http://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png', { attribution: 'Map &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a> | Tiles &copy; <a href=\"http://hot.openstreetmap.org\">Humanitarian OSM Team</a>', minZoom: 0, maxZoom: 20 }),
      'HERE': Leaflet.tileLayer(`https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/${style}/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}&ppi=320`,{
        attribution:"HERE",
        maxZoom:19
      }),
        'HERE HYBRID': Leaflet.tileLayer(`https://2.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/512/png8?app_id=${here.id}&app_code=${here.code}`,{
        attribution: 'HERE hybrid',
        maxZoom: 19
      }),
      'Google Streets': Leaflet.tileLayer('http://www.google.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}', {
	      attribution: 'google',
	      maxZoom: 20,
      }),           
      "Google Traffic": Leaflet.tileLayer('https://{s}.google.com/vt/lyrs=m@221097413,traffic&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20,
          minZoom: 2,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      }),
      "Google Hybrid": Leaflet.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
          attribution: 'google',
          maxZoom: 20
      })  
    }).addTo(this.map);

  }

  seguirUnidad(){
    this._api.getObjeto( {imei:this.id} ).subscribe((data:any)=>{
      // console.log(data, this.dataObjeto.lat, data.data[0].lat, this.dataObjeto.lng , data.data[0].lng );
      if(this.dataObjeto.lat != data.data[0].lat || this.dataObjeto.lng != data.data[0].lng ){
        this.dataObjeto = data.data[0];
        this.url = this.sanitizer.bypassSecurityTrustResourceUrl(      
          `http://trackingservice.com.mx/street-view.php?lat=${this.dataObjeto.lat}&lng=${this.dataObjeto.lng}&direccion=${this.dataObjeto.direccion}&speed=${this.dataObjeto.speed}&ignicion=0&fecha=0&ubic=F-350%20SLP&eco=F-350%20SL`
        );

      }
      this.dataObjeto = data.data[0];
      this.deleteMarkers();
      this.addMrcas(data.data[0], 1);
    });
  }

  closeOpen(){
    this.open_close = this.open_close ? false : true;
    setTimeout(()=>{
      this.map.invalidateSize();
    }, 500);
  }

  addMrcas( dataUbic:any, status:number ){
    let iconGps:any;
    let marker:any;    
    
    if( dataUbic.speed <= 4 &&  dataUbic.acc == 1  ){

      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-amarilla.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }else if(  dataUbic.speed <= 4 &&  dataUbic.acc == 0  ){

      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-roja.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }else if(  dataUbic.speed > 4 &&  dataUbic.acc == 1 ){
      iconGps = Leaflet.icon({
          iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-verde.png'),
          iconSize: this.iconSize( dataUbic ),//[28, 41],
          iconAnchor: this.iconAnchor( dataUbic ),//[14, 20],
          popupAnchor: [-1, -30]
      });
    }

    if( iconGps ){
      console.log("recibe encendido");
      marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })
                        // .bindPopup(`<strong>Nombre:</strong> ${dataUbic.nombre}<br><strong>Dirección:</strong>${dataUbic.direccion}
                        //             <br><strong>Cerca de:</strong>${dataUbic.pdi}<br><strong>Ubicación:</strong>
                        //             <a href="https://www.google.com.mx/maps?q=${this.number(dataUbic.lat)},${this.number(dataUbic.lng)}"  target="_blank">
                        //             ${dataUbic.lat},${dataUbic.lng}</a><br><strong>Velocidad: </strong>${this.speed( dataUbic.speed )}Km/h<br>
                        //             <strong>Status: </strong>${this.timeConversion( dataUbic.status_motor )} ${this.tipoStatus(dataUbic.speed, dataUbic.acc)}<br>
                        //             <strong>Motor:</strong>${this.motor(dataUbic.acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}<br>`).addTo(this.map);
    }else{
      /*Por si no recibe encendido hacerlo con velicudad*/
      if( dataUbic.speed > 4 ){
        console.log("NO encendido");
        iconGps = Leaflet.icon({
            iconUrl: this.validaUrl( dataUbic.url_icon, '../../../images/assets/marca-verde.png'),
            iconSize: this.iconSize( dataUbic ),
            iconAnchor: this.iconAnchor( dataUbic ),
            popupAnchor: [-1, -30]
        });
      
        marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })
                          // .bindPopup(`<strong>Nombre:</strong> ${dataUbic.nombre}<br><strong>Dirección:</strong>${dataUbic.direccion}
                          //             <br><strong>Cerca de:</strong>${dataUbic.pdi}<br><strong>Ubicación:</strong>
                          //             <a href="https://www.google.com.mx/maps?q=${this.number(dataUbic.lat)},${this.number(dataUbic.lng)}"  target="_blank">
                          //             ${dataUbic.lat},${dataUbic.lng}</a><br><strong>Velocidad:</strong>${this.speed(dataUbic.speed)}Km/h<br>
                          //             <strong>Status: </strong>${this.timeConversion( dataUbic.status_motor )} ${this.tipoStatus(dataUbic.speed, dataUbic.acc)}<br>
                          //             <strong>Motor:</strong>${this.motor(dataUbic.acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}<br><a href="javascript:;" onclick="mostrarStreetView(${dataUbic.lat},${dataUbic.lng},'${dataUbic.nombre}','${dataUbic.direccion}','${ this.speed( dataUbic.speed )}',${dataUbic.acc}, '${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}')"><img src="../../../assets/images/sv-min.png" width="16px" />Vista Calle</a>`).addTo(this.map);

      }else{
        
        iconGps = Leaflet.icon({
            iconUrl: this.validaUrl( dataUbic.url_icon,'../../../assets/images/marca-roja.png'),
            iconSize: this.iconSize( dataUbic ),
            iconAnchor: this.iconAnchor( dataUbic ),
            popupAnchor: [-1, -30]
        });

        marker = new Leaflet.marker([this.number(dataUbic.lat), this.number(dataUbic.lng)], {icon:iconGps, rotationAngle:this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) })
                          // .bindPopup(`<strong>Nombre:</strong> ${dataUbic.nombre}<br><strong>Dirección:</strong>${dataUbic.direccion}
                          //             <br><strong>Cerca de:</strong>${dataUbic.pdi}<br><strong>Ubicación:</strong>
                          //             <a href="https://www.google.com.mx/maps?q=${this.number(dataUbic.lat)},${this.number(dataUbic.lng)}"  target="_blank">
                          //             ${dataUbic.lat},${dataUbic.lng}</a><br><strong>Velocidad:</strong>${this.speed(dataUbic.speed)}Km/h<br>
                          //             <strong>Status: </strong>${this.timeConversion( dataUbic.status_motor )} ${this.tipoStatus(dataUbic.speed, dataUbic.acc)}<br>
                          //             <strong>Motor:</strong>${this.motor(dataUbic.acc)}<br><strong>Fecha:</strong>${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}<br><a href="javascript:;" onclick="mostrarStreetView(${dataUbic.lat},${dataUbic.lng},'${dataUbic.nombre}','${dataUbic.direccion}','${this.speed(dataUbic.speed)}',${dataUbic.acc}, '${this.getTIMESTAMP( this.number(dataUbic.timestamp) )}')"><img src="../../../assets/images/sv-min.png" width="16px" />Vista Calle</a>`).addTo(this.map);
      }
    }

    if( status == 1 ){

    //   Leaflet.popup()
    // .setLatLng([this.number(dataUbic.lat), this.number(dataUbic.lng)])
    // .setContent('<p>Hello world!<br />This is a nice popup.</p>')
    // .openOn(this.map);

      this.tipo_status = `${this.timeConversion( dataUbic.status_motor )} ${this.tipoStatus(dataUbic.speed, dataUbic.acc)}`;
      this.fecha = this.getTIMESTAMP( dataUbic.timestamp );
      this.fecha_server = this.getTIMESTAMP( dataUbic.time_server );

      this.map.setView([dataUbic.lat, dataUbic.lng]);

      marker.addTo(this.map);;
      
      if( this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) > 0 && this.validaAngulo(dataUbic.url_icon_angulo,dataUbic.angulo) <= 180){
        marker.bindTooltip( `${dataUbic.nombre} - (${this.speed(dataUbic.speed)}Km/h)`, {permanent:true, offset: Leaflet.point({ x: this.poitOffset( dataUbic ), y: 0 }), direction:'left' });
      }else{
        marker.bindTooltip(dataUbic.nombre, {permanent:true, offset: Leaflet.point({x: this.poitOffset( dataUbic ) + 30, y: 0}), direction:'right' });
      }
      this.markers.push(marker);      
      
    }

    if( status == 2 ){
      this.markersHistorial.push(marker);
    }

  }

  deleteMarkersHistorial(){
    for(var i = 0; i < this.markersHistorial.length; i++){
      this.map.removeLayer(this.markersHistorial[i]);
    }
  }

  marcaPorVelocidad( speed:number ){
    if(  speed ){
      if( speed < 30 ){
        return 0.3;
      }else{
        return 0.8;
      }
    }else{
      return 0.3;
    }
  }

  inpar( valor:any ){
    return (valor.length - 1);
  }

  creaLineasRecorrido( data:any ){    
    let num = 0;
    for( let unidad of data ){
      num++;   
      
      this.lineasPushAgrupadas.push( unidad.data ); 

      if( data.length == num ){        
        if( this.lineasObjetos ){
          this.map.removeLayer(this.lineasObjetos);
        }
        this.lineasObjetos = new Leaflet.polyline(this.lineasPushAgrupadas, {color: 'blue'}).addTo(this.map);        
      }
    }
  }

  estelaRecorrido( data:any){

    if( this.numTempObjeto == 1 ){

      for( let objeto of data ){
        this.lineaTempObjeto.push( { "imei":objeto.imei, data:[[objeto.lat, objeto.lng]] } );        
      }

    }else if( this.numTempObjeto > 1 && this.numTempObjeto < 6 ){

      let num = 0;
      for( let objeto of this.lineaTempObjeto ){        
        for( let objetoNew of data ){
          if( this.lineaTempObjeto[num].imei == objetoNew.imei ){
            this.lineaTempObjeto[num].data.push( [ objetoNew.lat, objetoNew.lng ] );                                 
          }                   
        }

        if( num == data.length - 1  ){
          if( this.lineaTempObjeto.length > 0 ){
            this.creaLineasRecorrido( this.lineaTempObjeto );
          }        
        }
        num++;
      }
          
    }else{
      this.numTempObjeto   = 0;
      this.lineaTempObjeto = [];
      if(this.lineasObjetos){
        this.map.removeLayer(this.lineasObjetos);
      }
      this.lineasPushAgrupadas = [];
    }    

  }

  // historialMapa(event:any){

  //   if( this.polyline ){
  //     this.map.removeLayer(this.polyline);
  //   }

  //   if( this.markersHistorial ){
  //     this.deleteMarkersHistorial();
  //   }

  //   let data = [];
  //   let dataPolilinea = [];
  //   let distancia:number;

  //   if( event.length > 1 ){
      
  //     if( event.length % 2 == 0 ){

  //       let num  = 0;
  //       let num2 = 1;
        
  //       data.unshift([event[0].lat, event[0].lng]);
  //       this.addMrcas(event[0], 2);

  //       for( let i = 0; i < event.length - 1; i++ ){
  //         console.log(event[num].speed);
  //         dataPolilinea.push([event[i].lat, event[i].lng]);
  //         distancia = this.getDistanciaLatLngEnKm( event[num].lat, event[num].lng, event[num2].lat, event[num2].lng );

  //         if( distancia >= this.marcaPorVelocidad( event[num].speed ) ){
  //           data.push([event[i].lat, event[i].lng]);
  //           this.addMrcas(event[i], 2);
  //         }

  //         if( (num+1) == event.length - 1 ){
  //           data.push([event[event.length-1].lat, event[event.length-1].lng]);
  //           // $("#loading").hide(300);
  //           let options = {
  //               "delay":686,
  //               "dashArray":[
  //                 10,
  //                 20
  //               ],
  //               "weight":5,
  //               "color":"#222",
  //               "pulseColor":"#000000",
  //               "paused":false,
  //               "reverse":false,
  //               "hardwareAccelerated":true
  //             };
                
  //             this.polyline = Leaflet.polyline.antPath(dataPolilinea, options).addTo(this.map);
  //             this.map.fitBounds(this.polyline.getBounds());
      
  //         }

  //         num++;
  //         num2++;
  //       }

  //     }else{

  //       let num  = 0;
  //       let num2 = 1;

  //       data.unshift([event[0].lat, event[0].lng]);
  //       this.addMrcas(event[0], 2);

  //       for( let i = 0; i < this.inpar( event ); i++ ){
          
  //         dataPolilinea.push([event[i].lat, event[i].lng]);
  //         distancia = this.getDistanciaLatLngEnKm( event[num].lat, event[num].lng, event[num2].lat, event[num2].lng );

  //         if( distancia >= this.marcaPorVelocidad( event[num].speed ) ){
  //           this.addMrcas(event[i], 2);
  //           data.push([event[i].lat, event[i].lng]);
  //         }

  //         if( this.inpar( event ) == num+1 ){
  //           // $("#loading").hide(300);
  //           let options = {
  //               "delay":686,
  //               "dashArray":[
  //                 10,
  //                 20
  //               ],
  //               "weight":5,
  //               "color":"#222",
  //               "pulseColor":"#000000",
  //               "paused":false,
  //               "reverse":false,
  //               "hardwareAccelerated":true
  //             };
              
  //             this.polyline = Leaflet.polyline.antPath(dataPolilinea, options).addTo(this.map);
  //             this.map.fitBounds(this.polyline.getBounds());
  //         }

  //         num++;
  //         num2++;
          
  //       }

  //     }

  //   }

  //   if( event.length == 1 ){
  //     // $("#loading").hide(300);
  //     this.map.setView([ event[0].lat, event[0].lng ], 19);
  //     this.addMrcas(event[0], 2);
  //   }

  //   if( event.length == 0 ){
  //     // $("#loading").hide(300);
  //     // this.notificacion_msj( "Sin registros", "No se encontraron registros", "danger" );
  //   }
    
  // }

  deleteMarkers(){
    for(var i = 0; i < this.markers.length; i++){
			this.map.removeLayer(this.markers[i]);
		}
  }

  validaUrl( url_server:any, url:any ){    
    if( url_server !== "null" && url_server !== undefined ){
      return `${url_server}`;
    }else{
      return `${url}`;
    }
  }

  private number(value: any): number {
    if( value == "0" ){
      return 0;
    }else{
      return + value;
    }
  }

  iconSize( data:any ){

    if( data.url_icon == undefined || data.url_icon == "null" ){
      return [28, 40];
    }
    //console.log(data.url_icon);
    if( data.url_icon !== "null" ){         
      if( data.url_icon_angulo == 0 ){
        let size = JSON.parse( atob( data.size_icon ) );        
        return [ size.size_ancho, size.size_largo ]
      }else{      
        return [ 25, 50 ]
      }
    }else{           
      return [28, 40];
    }
  }

  iconAnchor( data:any ){

    if( data.url_icon == undefined || data.url_icon == "null" ){
      return [14, 20];
    }

    if( data.url_icon !== "null" ){
      if( data.url_icon_angulo == 0 ){
        let size = JSON.parse( atob( data.size_icon ) ); 
        return [ size.size_ancho/2, size.size_largo/2 ]
      }else{
        return [ 12.5, 25 ]
      }
    }else{
      return [14, 20];
    }
  }

  poitOffset( data:any ){

    if( data.url_icon == undefined ){
      return -10;
    }

    if( data.url_icon !== "null" ){
      if( data.url_icon_angulo == 0 ){
        let size = JSON.parse( atob( data.size_icon ) );        
        return size.offset;
      }else{
        return -25;
      }
    }else{
      return -10;
    }
  }

  timeConversion(millisec:any) {	
    if( millisec ){				
        let seconds = (millisec / 1000).toFixed(1);
        var minutes = (millisec / (1000 * 60)).toFixed(1);
        var hours = (millisec / (1000 * 60 * 60)).toFixed(1);
        var days = (millisec / (1000 * 60 * 60 * 24)).toFixed(1);

        if (parseFloat(seconds) < 60) {
            return seconds + " seg";
        } else if (parseFloat(minutes) < 60) {
            return minutes + " min";
        } else if (parseFloat(hours) < 24) {
            return hours + " hrs";
        } else {
            return days + "Días"; 
        }
    }else{
        return 0;
    }
  }

  motor( status:any ){

    if( status == "0" ){
      return "Apagado";
    }else if( status == "1" ){
      return "Encendido";
    }else{
      return "!";
    }
  }

  getTIMESTAMP(timestamp:any) {
    let months:any = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    let date:any = new Date(timestamp);
    let year:any = date.getFullYear();
    let month:any = ("0"+(date.getMonth()+1)).substr(-2);
    let day = ("0"+date.getDate()).substr(-2);
    let hour = ("0"+date.getHours()).substr(-2);
    let minutes = ("0"+date.getMinutes()).substr(-2);
    let seconds = ("0"+date.getSeconds()).substr(-2);

    return months[month-1]+" "+day+", "+year+" "+hour+":"+minutes+":"+seconds;
  }

  validaAngulo( status:any, angulo:any ){    
    //console.log( status, angulo );
    if( status == undefined || status == 2 ){      
      return angulo;
    }

    if( status == 1 ){
      return angulo;
    }else{
      return 0;
    } 
  }

  speed( speed:any ){
    if( speed ){   
      let newSpeed = parseFloat( speed ) / 0.62137;  
      return newSpeed.toFixed(1);
    }else{
      return "0";
    }
  }

  tipoStatus( speed:number, acc:number ){

    if( acc == 1 && speed < 5 ){
      return "detenido encendido";
    }

    if( speed > 5 ){
      return "en movimiento";
    }else{
      if( acc == 0 ){
        return "apagado"
      }
      return "detenido";
    }
    
  }

  getDistanciaLatLngEnKm( lat1, lon1, lat2, lon2 ){

    let R = 6378.137;
    let dLat = this.deg2rad( lat2 - lat1 );
    let dLon = this.deg2rad( lon2 - lon1 );

    let a =  Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon/2) * Math.sin(dLon/2);
    let c = 2 * Math.atan2( Math.sqrt(a), Math.sqrt(1-a) );
    let d = R * c;
    return d;
  }

  deg2rad(n){
    return n * (Math.PI/180);
  }

  segmentChanged( event:any ){
    console.log(this.segmentModel);
    this.segmentModel = event.detail.value;
    console.log(event);
  }
  

}
