import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SeguirObjetoPageRoutingModule } from './seguir-objeto-routing.module';

import { SeguirObjetoPage } from './seguir-objeto.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SeguirObjetoPageRoutingModule
  ],
  declarations: [SeguirObjetoPage]
})
export class SeguirObjetoPageModule {}
